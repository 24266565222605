<template>
  <div>
    <base-section id="resource-account">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <base-info-card :title="$t('AccountEU.mainTitle')" />
          </v-col>
        </v-row>
        <!------- Display of the different elements ------------------------>
        <v-row
          v-for="(myResource, r) in myResourcesList.list"
          :key="'row' + r"
          dense
        >
          <kdw-dialog-resource
            :key="'ressource' + refreshFront"
            v-model="myResourcesList.list[r]"
            :index="r"
            :deleted-possible="false"
            :service-displayed="false"
            :external-access-displayed="false"
            :online-access-displayed="false"
            @updateResource="updateResource(r, $event)"
          />
        </v-row>
        <!--
        <v-row
          v-for="(myResource, r) in myResourcesList.list"
          :key="'row' + r"
          dense
        >
          <kdw-resource
            :key="'ressource' + refreshFront"
            v-model="myResourcesList.list[r]"
            :index="r"
            @deleteResource="deleteResource(r)"
            @updateResource="updateResource(r, $event)"
          />
        </v-row>
        -->
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
// import { API } from 'aws-amplify'
import { getUserSub } from "@/services/User";
import { ResourceList } from "@/services/ResourceList";

@Component({ name: "ResourceAccount" })
class ResourceAccount extends Vue {
  myResourcesList = new ResourceList();
  refreshFront = 0;

  created() {
    getUserSub().then((id) => {
      // this.myResource.id = id
      // this.myResource.loadResource(id)
      this.myResourcesList.addResourceFromId(id).then(() => {
        this.refreshFront++;
      });
      // console.log(this.myResource.id)
    });
  }

  updateResource(index, resource) {
    // console.log(index)
    // console.log(resource)
    this.myResourcesList.updateResource(resource, index).then((result) => {
      if (result) {
        this.$root.kdwsnackbar.alert("ressources.alertSucessUpdateRes");
      } else {
        this.$root.kdwsnackbar.alert("ressources.alertFailUpdateRes");
      }
    });
  }
}

export default ResourceAccount;
</script>
